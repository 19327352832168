<template>
  <div v-if="isLoaded()" class="rating-management">
    <NotiBar 
      v-if="noti.show" 
      :type="noti.type" 
      :msg="noti.text"
    />

    <form 
      v-if="isShowAddNew()"
      @submit.prevent="saveNewRating"
      class="add-rating-form"
    >
      <fieldset>
        <div 
          class="input" 
          :class="{ error: $v.newRating.rating_by.$error }"
        >
          <Label text="ชื่อผู้ให้คะแนน" />
          <input 
            type="text" 
            v-model.trim="$v.newRating.rating_by.$model"
          >
        </div>
        <div 
          class="input" 
          :class="{ error: $v.newRating.message.$error }"
        >
          <Label text="ความคิดเห็น" />
          <textarea 
            v-model.trim="$v.newRating.message.$model" 
            rows="8"
          ></textarea>
        </div>

        <div class="row3 input">
          <div 
            v-for="(item, index) in cat" 
            :key="index" 
            class="col"
          >
            <Label :text="item.name" />
            <input 
              type="number" 
              step="0.1"
              min="0"
              max="10"
              v-model="newRating['stat' + index]" 
              class="very-extra-small" 
            />
          </div>
        </div>
      </fieldset>

      <div class="button-block">
        <button 
          type="submit" 
          class="
            btn 
            btn-update 
            btn-small
          "
        >
          <i class="fa fa-save"></i> Save
        </button>
        
        <button 
          @click="clickCancelAddNew" 
          type="button" 
          class="
            btn 
            btn-cancel 
            btn-small
          "
        >
          <i class="fa fa-close"></i> Cancel
        </button>
      </div>
    </form>
    <!-- add new rating -->


    <div v-if="!isShowAddNew()">
      <div class="data-control">
        <div class="btn-set">
          <a
            class="btn btn-add" 
            @click="clickAddNew"
          >
            <i class="fa fa-plus"></i> 
            Add 
            <span class="mobile-hide">Rating</span>
          </a>
        </div>
      </div>
      <!-- end: data control -->

      <EmptyBlockComp 
        v-if="isListEmpty()" 
      />
      <div 
        v-else 
        class="rating-list"
      >
        <RatingItem v-for="(item, index) in ratings.result" :key="index" :item="item" />
      </div>
    </div>
    <!-- rating and comment list -->

  </div>
</template>

<script>
// import valiables
import { memberRatingCat } from '@/variables/const.js';

// Import library
const { required } = require('vuelidate/lib/validators')
import {apiRequest} from '@/utils/axios/axiosInstance.js';

// Import component
import Label          from '@/components/common/form/Label.vue';
import NotiBar        from '@/components/common/NotiBar.vue';
import EmptyBlockComp from '@/components/common/EmptyBlockComp.vue';
import RatingItem     from '@/components/content/recommended/RatingItem.vue';


// start component script
export default {
  data() {
    return {
      showAddNew: false,

      postId: '',
      cat: {},
      ratings: {
        row: 0
      },
      newRating: {},

      noti: {
        show: false,  
        type:'success',
        text:'',
      },
    }
  },
  validations: {
    newRating: {
      rating_by:{required},
      message:  {required},
    },
  },
  components: {
    EmptyBlockComp,
    Label,
    NotiBar,
    RatingItem,
  },
  created() {
    this.$store.commit('setPageTitle', 'Recommended');
    this.$store.commit('setHeadTitle', 'Recommended');

    this.postId = this.$route.params.id;
    this.cat    = memberRatingCat;

    this.loadPage();
  },
  methods: {
    /**
     * Detect this page is loaded
     */
    isLoaded() {
      return !this.$store.state.show_loader;
    },

    /**
     * detect admin is creating new rating
     */
    isShowAddNew() {
      return this.showAddNew === true;
    },

    /**
     * 
     */
    isListEmpty() {
      return this.ratings.row == 0;
    },

    /**
     * loadPage
     *  - load Rating list from API
     */
    loadPage() {
      this.$store.commit('setLoaderShow', true);

      apiRequest
        .get('/content-rating', {params: {
          post_id: this.postId
        }})
        .then( (res) => {
          this.ratings = res.data;
          
          this.$store.commit('setLoaderShow', false);
        })
    },

    /**
     * Click handler for add new rating button
     */
    clickAddNew() {
      this.showAddNew = true;
      this.newRating  = {
        content_id: '',
        rating_by: '',
        message: '',
        stat1: 0.0,
        stat2: 0.0,
        stat3: 0.0,
        stat4: 0.0,
        stat5: 0.0,
        stat6: 0.0,
        stat7: 0.0,
      };
    },

    /**
     * Cancel add new
     *  this function will change show add new form status to hide
     *  and clear add new data
     */
    clickCancelAddNew() {
      this.showAddNew = false;
    },

    /**
     * Save data to APO from new rating
     */
    saveNewRating() {
      this.$store.commit('setLoaderShow', true);

      this.newRating.content_id = this.postId;
      apiRequest
        .post('/content-rating/add', this.newRating)
        .then( () => this.$router.go() );
      this.showAddNew = false;
    }
  }
}
</script>

<style scoped>
.rating-management {
  float: none;
  margin: auto;
  max-width: 1000px;
}

.add-rating-form {
  float: none;
  margin: auto;
  max-width: 640px;
}
.add-rating-form .col {
  padding: 0 10px 20px 0;
}

.btn-small {
  padding: 5px 30px 7px 30px;
  font-size: 13px;
  line-height: 22px;
  font-weight: 500;
}




@media all and (max-width: 720px) {
  .add-rating-form .col {
    float: none;
    padding: 0 0 20px 0;
    width: auto;
  }
}
</style>