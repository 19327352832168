<template>
  <div class="rating-item" :class="{'hide': this.hide}">
    <div v-if="!isEdit" class="rating-info">
      <div class="title">
        <h3>{{ item.rating_by }}</h3>
  
        <ul class="control">
          <li>
            <a 
              href="#"
              @click.prevent="clickEditHandler" 
              class="edit"
            >
              <i class="fa fa-edit"></i> edit
            </a>
          </li>
          <li>
            <a 
              href="#"
              @click.prevent="clickRemoveHandler(item.ratingID)" 
              class="remove"
            >
              <i class="fa fa-trash"></i> remove
            </a>
          </li>
        </ul>
      </div>
  
      <div class="content">
        <p>{{ item.message }}</p>
      </div>
  
      <div class="stat-row">
        <div class="overall">
          {{ item.stat_overall | statDigit }}
        </div>
  
        <ul class="stat-list row2">
          <li 
            v-for="(c, index) in cat" 
            :key="index" 
            class="col stat-item"
          >
            <span class="stat">{{ item['stat'+index] }}</span>
            {{ c.name }}
          </li>
        </ul>
      </div>
    </div>




    <form 
      v-if="isEdit"
      @submit.prevent="saveRating"
      class="add-rating-form"
    >
      <fieldset>
        <div class="input" >
          <Label text="ชื่อผู้ให้คะแนน" />
          <input 
            type="text" 
            v-model.trim="item.rating_by"
          >
        </div>
        <div class="input">
          <Label text="ความคิดเห็น" />
          <textarea 
            v-model.trim="item.message" 
            rows="8"
          ></textarea>
        </div>

        <div class="row3 input">
          <div 
            v-for="(c, index) in cat" 
            :key="index" 
            class="col"
          >
            <Label :text="c.name" />
            <input 
              type="number" 
              step="0.1"
              min="0"
              max="10"
              v-model="item['stat' + index]" 
              class="very-extra-small" 
            />
          </div>
        </div>
      </fieldset>

      <div class="button-block">
        <button 
          type="submit" 
          class="
            btn 
            btn-update 
            btn-small
          "
        >
          <i class="fa fa-save"></i> Save
        </button>
        
        <button 
          @click="cancelEditRating" 
          type="button" 
          class="
            btn 
            btn-cancel 
            btn-small
          "
        >
          <i class="fa fa-close"></i> Cancel
        </button>
      </div>
    </form>
    <!-- add new rating -->
  </div>
</template>

<script>
// import valiables
import { memberRatingCat } from '@/variables/const.js';

// Import library
import {apiRequest} from '@/utils/axios/axiosInstance.js';

// Import component
import Label          from '@/components/common/form/Label.vue';

// start component script
export default {
  props: {
    item: {
      type: Object,
      default: function() {
        return {
          row: 0
        }
      },
    }
  },

  data() {
    return {
      hide: false,
      isEdit: false,
      cat: {}
    }
  },

  components: {
    Label,
  },

  mounted() {
    this.cat = memberRatingCat;
  },

  filters: {
    statDigit: function(stat) {
      // console.log(typeof stat);
      return Number(stat).toFixed(1);
    },
  },

  methods: {
    /**
     * edit rating content
     */
    clickEditHandler() {
      this.isEdit = true;
    },

    /**
     * save rating data
     */
    saveRating() {
      this.item.stat_overall = (Number(this.item.stat1) +
                               Number(this.item.stat2) +
                               Number(this.item.stat3) +
                               Number(this.item.stat4) +
                               Number(this.item.stat5) +
                               Number(this.item.stat6) +
                               Number(this.item.stat7)) / 7;
      
      apiRequest
        .post('/content-rating/update', this.item)
        .then( () => {
          this.isEdit = false
        });
    },
    
    /**
     * save rating data
     */
    cancelEditRating() {
      this.isEdit = false;
    },

    /**
     * remove this item when click delete button
     *  - display loader in this item
     *  - send remove item request to API
     *  - hide this item (if refresh, this item is not display forever)
     */
    clickRemoveHandler(id) {
      let conf = confirm('Do you want to delete this review by ' + 
                  this.item.review_by + ' ?');
      if(conf) {
        this.hide = true;

        this.$store.commit('setLoaderShow', true);
        apiRequest
          .delete('/content-rating/delete', {data: {rating_id: id}})
          .then( () => this.$router.go() );
      }
    }
  }
}
</script>

<style scoped>
.rating-item {
  margin-bottom: 20px;
  padding: 20px;
  border-radius: 10px;
  border: solid 1px #ddd;
  background: #fff;
  box-shadow: 0 1px 5px rgba(0,0,0,0.1);
}
.rating-item.hide {
  display: none;
}
.title {
  position: relative;
  margin-bottom: 15px;
  padding-bottom: 10px;
  border-bottom: solid 1px #ccc;
}
.title h3 {
  font-size: 16px;
  font-weight: 500;
}
.title .control {
  position: absolute;
  top: 2px;
  right: 0;
}
.title .control li {
  display: inline-block;
}
.title .control li+li {
  margin-left: 15px;
}
.title .remove {
  color: #c00
}

.content {
  padding-bottom: 20px;
  border-bottom: solid 1px #ccc;
  font-size: 16px;
  line-height: 26px;
}

.stat-row {
  position: relative;
  margin-top: 20px;
  padding-left: 180px;
}
.stat-row .overall {
  position: absolute;
  top: 0;
  left: 0;
  padding: 8px 10px;
  border-radius: 8px;
  width: 120px;
  background: #333;
  text-align: center;
  font-size: 36px;
  line-height: 40px;
  color: #fff;
}
.stat-list li {
  margin-bottom: 8px;
}
.stat {
  display: inline-block;
  border-radius: 20px;
  width: 40px;
  background: #eee;
  text-align: center;
}


.add-rating-form .col {
  padding: 0 10px 20px 0;
}

@media all and (max-width: 720px) {
  .add-rating-form .col {
    float: none;
    padding: 0 0 20px 0;
    width: auto;
  }
}
</style>